<template>
  <div v-if="cards && Object.values(cards).length" class="hand">
    <component
      v-for="(card, key) in cards"
      :key="key"
      :is="cardComponent"
      v-bind="card"
      :class="{ selected: selectedCard === key }"
      @click="select(card, key)"
    />
  </div>
  <p v-else-if="recent" class="empty">No recently played cards.</p>
  <p v-else class="empty">Your hand is currently empty.</p>
</template>

<script>
export default {
  name: 'PlayerHand',
  props: {
    cardComponent: Function,
    cards: {
      type: Object,
      default: () => {},
    },
    selectedCard: String,
    recent: Boolean,
  },
  methods: {
    select(card, key) {
      this.$emit('select', this.selectedCard === key ? null : key);
    },
  },
};
</script>

<style scoped lang="scss"></style>
