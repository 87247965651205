const cards = [];
const trains = ['red', 'orange', 'yellow', 'green', 'blue', 'purple', 'white', 'black', 'wild'];

cards.push(
  trains.map((color) => ({
    type: 'train',
    color,
  })),
);

const tickets = [];
tickets.push({
  type: 'ticket',
  start: 'Boston, MA',
  end: 'Miami, FL',
  points: 12,
});
tickets.push({
  type: 'ticket',
  start: 'Calgary, CAN',
  end: 'Phoenix, AZ',
  points: 13,
});
tickets.push({
  type: 'ticket',
  start: 'Calgary, CAN',
  end: 'Salt Lake City, UT',
  points: 7,
});
tickets.push({
  type: 'ticket',
  start: 'Chicago, IL',
  end: 'New Orleans, LA',
  points: 7,
});
tickets.push({
  type: 'ticket',
  start: 'Chicago, IL',
  end: 'Santa Fe, NM',
  points: 9,
});
tickets.push({
  type: 'ticket',
  start: 'Dallas, TX',
  end: 'New York, NY',
  points: 11,
});
tickets.push({
  type: 'ticket',
  start: 'Denver, CO',
  end: 'El Paso, TX',
  points: 4,
});
tickets.push({
  type: 'ticket',
  start: 'Denver, CO',
  end: 'Pittsburgh, PN',
  points: 11,
});
tickets.push({
  type: 'ticket',
  start: 'Duluth, MN',
  end: 'El Paso, TX',
  points: 10,
});
tickets.push({
  type: 'ticket',
  start: 'Duluth, MN',
  end: 'Houston, TX',
  points: 8,
});
tickets.push({
  type: 'ticket',
  start: 'Helena, MT',
  end: 'Los Angeles, CA',
  points: 8,
});
tickets.push({
  type: 'ticket',
  start: 'Kansas City, KS',
  end: 'Houston, TX',
  points: 5,
});
tickets.push({
  type: 'ticket',
  start: 'Los Angeles, CA',
  end: 'Chicago, IL',
  points: 16,
});
tickets.push({
  type: 'ticket',
  start: 'Los Angeles, CA',
  end: 'Miami, FL',
  points: 20,
});
tickets.push({
  type: 'ticket',
  start: 'Los Angeles, CA',
  end: 'New York, NY',
  points: 21,
});
tickets.push({
  type: 'ticket',
  start: 'Montreal, CAN',
  end: 'Atlanta, GA',
  points: 9,
});
tickets.push({
  type: 'ticket',
  start: 'Montreal, CAN',
  end: 'New Orleans, LA',
  points: 13,
});
tickets.push({
  type: 'ticket',
  start: 'New York, NY',
  end: 'Atlanta, GA',
  points: 6,
});
tickets.push({
  type: 'ticket',
  start: 'Portland, OR',
  end: 'Nashville, TN',
  points: 17,
});
tickets.push({
  type: 'ticket',
  start: 'Portland, OR',
  end: 'Phoenix, AZ',
  points: 11,
});
tickets.push({
  type: 'ticket',
  start: 'San Francisco, CA',
  end: 'Atlanta, GA',
  points: 17,
});
tickets.push({
  type: 'ticket',
  start: 'Sault Ste. Marie, MI',
  end: 'Nashville, TN',
  points: 8,
});
tickets.push({
  type: 'ticket',
  start: 'Sault Ste. Marie, MI',
  end: 'Oklahoma City, OK',
  points: 9,
});
tickets.push({
  type: 'ticket',
  start: 'Seattle, WA',
  end: 'New York, NY',
  points: 22,
});
tickets.push({
  type: 'ticket',
  start: 'Seattle, WA',
  end: 'Los Angeles, CA',
  points: 9,
});
tickets.push({
  type: 'ticket',
  start: 'Toronto, CAN',
  end: 'Miami, FL',
  points: 10,
});
tickets.push({
  type: 'ticket',
  start: 'Vancouver, WA',
  end: 'Montreal, CAN',
  points: 20,
});
tickets.push({
  type: 'ticket',
  start: 'Vancouver, WA',
  end: 'Santa Fe, NM',
  points: 13,
});
tickets.push({
  type: 'ticket',
  start: 'Winnipeg, CAN',
  end: 'Houston, TX',
  points: 12,
});
tickets.push({
  type: 'ticket',
  start: 'Winnipeg, CAN',
  end: 'Little Rock, AR',
  points: 11,
});

cards.push(tickets);
export default {
  type: 'TicketToRide',
  name: 'Ticket to Ride',
  cardComponent: () => import('../games/TicketToRideCard.vue'),
  cards,
};
