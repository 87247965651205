<template>
  <div class="join">
    <div v-if="game" class="game">
      <h2>{{ game.name }} (Room Code: {{ room }})</h2>
      <div class="player">
        <div class="avatar"><img :src="currentUser.photoURL" /></div>
        <h3>{{ currentUser.displayName }}</h3>
      </div>
      <PlayerHand
        :cardComponent="game.cardComponent"
        :cards="hand"
        :selectedCard="selectedHandCard"
        @select="selectedHandCard = $event"
      />
      <button v-if="selectedHandCard" @click="playCard">Play Card</button>
    </div>
    <div v-else class="not-found">
      <h2>Room Not Found</h2>
      <p>We couldn't find a room with that game code.</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PlayerHand from '../components/PlayerHand.vue';
import { db } from '../firebaseConfig';
import standardDeck from '../games/StandardDeck';
import ticketToRide from '../games/TicketToRide';

export default {
  name: 'Join',
  components: {
    PlayerHand,
  },
  data() {
    return {
      room: '',
      game: null,
      games: [standardDeck, ticketToRide],
      hand: {},
      selectedHandCard: null,
    };
  },
  created() {
    if (this.$route.params.room && !this.game) {
      console.log('reading once', this.$route.params.room);
      db.ref(`/rooms/${this.$route.params.room}/type`)
        .once('value')
        .then((snapshot) => {
          const type = snapshot.val();
          if (type) {
            this.game = this.games.find((game) => game.type === type);
            if (this.game) this.room = this.$route.params.room;

            const joinData = {
              joined: new Date().toISOString(),
            };
            console.log('joining room', `/room_players/${this.room}/${this.currentUser.uid}`, joinData);
            db.ref(`/room_players/${this.room}/${this.currentUser.uid}`).update(joinData);

            const handRef = db.ref(`/hands/${this.room}/${this.currentUser.uid}`);
            // handRef.once('value').then((handSnapshot) => {
            //   this.hand = handSnapshot.val();
            // });
            handRef.on('child_added', this.cardAdded);
            handRef.on('child_removed', this.cardRemoved);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },
  computed: {
    ...mapState('user', ['currentUser']),
  },
  methods: {
    cardAdded(snapshot) {
      this.$set(this.hand, snapshot.key, snapshot.val());
      console.log('card added', snapshot.key);
    },
    cardRemoved(snapshot) {
      this.$delete(this.hand, snapshot.key);
      console.log('card removed', snapshot.key);
    },
    playCard() {
      db.ref(`/hands/${this.room}/${this.currentUser.uid}/${this.selectedHandCard}`).remove();
      this.selectedHandCard = null;
    },
  },
};
</script>

<style scoped lang="scss">
h2 {
  margin-top: 0;
}
button {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  display: block;
}
.player {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .avatar {
    border-radius: 50%;
    margin-right: 20px;
    overflow: hidden;
    width: 50px;
    height: 50px;

    img {
      display: block;
      width: 100%;
    }
  }
}
</style>
