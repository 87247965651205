const cards = [];
const suits = ['hearts', 'spades', 'diamonds', 'clubs'];
const values = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];

suits.forEach((suit) => {
  values.forEach((value) => {
    cards.push({
      suit,
      value,
    });
  });
});
cards.push({
  suit: 'joker',
  value: 'joker',
});
export default {
  type: 'StandardDeck',
  name: 'Standard Deck',
  cardComponent: () => import('../games/StandardDeckCard.vue'),
  cards,
};
